.text {

  &--regular10.ant-typography {
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
  }
  &--regular12.ant-typography {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }
  &--regular14.ant-typography {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  &--regular16.ant-typography {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  &--regular18.ant-typography {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
  }
  &--regular20.ant-typography {
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
  }

  //////////////////////////////////////

  &--medium12.ant-typography {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
  }
  &--medium14.ant-typography {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
  &--medium16.ant-typography {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  &--medium18.ant-typography {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
  }
  &--medium20.ant-typography {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
  }
  &--medium24.ant-typography {
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
  }

  //////////////////////////////////////

  &--semibold12.ant-typography {
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
  }
  &--semibold14.ant-typography {
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
  }
  &--semibold16.ant-typography {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
  &--semibold18.ant-typography {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
  }
  &--semibold20.ant-typography {
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
  }
  &--semibold24.ant-typography {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
  }
  &--semibold28.ant-typography {
    font-weight: 600;
    font-size: 28px;
    line-height: 42px;
  }
  &--semibold32.ant-typography {
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
  }
  &--semibold40.ant-typography {
    font-weight: 600;
    font-size: 40px;
    line-height: 60px;
  }

  //////////////////////////////////////

  &--bold12.ant-typography {
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
  }
  &--bold14.ant-typography {
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
  }
  &--bold16.ant-typography {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }
  &--bold18.ant-typography {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
  }
  &--bold20.ant-typography {
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
  }
  &--bold24.ant-typography {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
  }
  &--bold28.ant-typography {
    font-weight: 700;
    font-size: 28px;
    line-height: 42px;
  }
  &--bold32.ant-typography {
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
  }
  &--bold40.ant-typography {
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
  }
  &--bold56.ant-typography {
    font-weight: 700;
    font-size: 56px;
    line-height: 84px;
  }
  &--bold72.ant-typography {
    font-weight: 700;
    font-size: 72px;
    line-height: 108px;
  }

}
