.ant-table {

  &-wrapper {
    border: 1px solid $line;
    box-sizing: border-box;
    border-radius: 16px;
  }

  &-container {

  }

  &-content {

  }

  &-thead {
    font-size: 14px;
  }

  &-thead {
    th {
      @apply opacity-70;
    }
  }

  &-tbody {
    > tr {
      > td:first-child {
        position: relative;
        border-bottom: none;

        &:after {
          position: absolute;
          content: '';
          border-bottom: 1px solid #f0f0f0;
          left: 24px !important;
          right: 0;
          bottom: 0;
        }
      }

      > td:last-child {
        position: relative;
        border-bottom: none;

        &:after {
          position: absolute;
          content: '';
          border-bottom: 1px solid #f0f0f0;
          left: 0;
          right: 24px !important;
          bottom: 0;
        }
      }
    }

    > tr:last-child {
      > td {
        border-bottom: none;

        &:after {
          border-bottom: none;
        }
      }
    }
  }

  &-thead, &-tbody {
    > tr {
      > th:first-child, td:first-child {
        padding-left: 24px;
      }

      > th:last-child, td:last-child {
        padding-right: 24px;
      }
    }
  }
}

.ant-pagination {
  display: flex;
  flex-direction: row;

  &-total-text {
    flex: 1;
  }
}
