.home {

  .banner {
    @apply absolute top-0 left-0 right-0 w-full h-auto;
    @apply object-cover;
  }

  .close-friend-list {
    color: white;
    background-color: #7CC358;
    padding: 0 8px;
  }

  .instagram {
    color: #fd5949;
    background: #d6249f;
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .cause {
    background-image: url("/images/bg-cause.svg");
    @include tablet {
      background-image: url("/images/bg-cause-desktop.svg");
    }
    @include desktop {
      background-image: url("/images/bg-cause-desktop.svg");
    }
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #1F2931;

    .desc {
      @apply text-white text-center opacity-80 mt-16 mb-0 max-w-769;
    }
  }

  .intro-simulator {
    .circles {
      @apply w-full h-full object-contain transform origin-center xs:scale-175 translate-y-40 xxl:translate-y-0;
    }

    .line {
      @apply absolute;
      top: 25px;
      left: calc(50% - 95px);
      width: 190px;
    }

    .image {
      @apply absolute rounded-30 object-cover;
      width: 227px;
      height: 478px;
      top: 12px;
      left: calc(50% - 114px);
    }

    .user {
      @apply absolute;
      top: 35px;
      left: calc(50% - 95px);
      width: 190px;
    }
  }

  .discover {
    .search-bar {
      @apply w-full md:w-478 self-center;
    }

    .blur-bar-left  {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 100;
      width: 32px;
      height: 100%;
      background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
    }

    .blur-bar-right {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 100;
      width: 32px;
      height: 100%;
      background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
    }

    .creators-list {
      @apply -ml-12 pl-24 flex flex-col flex-wrap whitespace-nowrap overflow-y-hidden overflow-x-auto;
      @apply max-h-560 md:max-h-320;
      @include hidden-scrollbar;

      .discover-item-card {
        @apply w-full -mt-50 pt-56 md:-mt-64 md:pt-64 bg-white rounded-20 flex flex-col items-center p-16;
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
      }
    }
  }

  .application {
    .image {
      @apply w-full h-150 md:h-300 object-cover object-bottom;
    }
  }
}
