.creator-detail {

  @include mobile {
    height: calc(100vh - 64px /* header */);
    height: calc(var(--vh, 1vh) * 100 - 64px);
    transition: all 0.3s;
  }

  .content {
    @apply container mx-auto px-16 py-16;
  }

  .payment-flow-card {
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  }
}

.creator-profile,
.subscribers,
.income {
  @apply container mx-auto max-w-560;

  @include mobile {
    height: calc(100vh - 64px/* header */);
    height: calc(var(--vh, 1vh) * 100 - 64px);
    transition: all 0.3s;
  }

  @include tablet-desktop {
    @apply w-full mx-0 max-w-full;
  }

  .content {
    @apply container mx-auto max-w-560 px-16 py-16 flex flex-col items-start;

    @include tablet-desktop {
      @apply w-full mx-0 max-w-full;
    }
  }
}
