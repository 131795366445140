.profile,
.update-password,
.purchases {
  @apply container mx-auto max-w-560;

  @include mobile {
    height: calc(100vh - 64px/* header */ - 48px/* tabs */);
    height: calc(var(--vh, 1vh) * 100 - 64px - 48px);
    transition: all 0.3s;
  }

  @include tablet-desktop {
    @apply w-full mx-0 max-w-full;
  }

  .content {
    @apply container mx-auto max-w-560 px-16 py-16 flex flex-col items-start;

    @include tablet-desktop {
      @apply w-full mx-0 max-w-full;
    }
  }
}

.update-password {

  @include mobile {
    height: calc(100vh - 64px /* header */);
    height: calc(var(--vh, 1vh) * 100 - 64px);
  }
}
