$default: #1c222e;
$primary: #7CC358;
$blue: #2f80ed;
$white: #ffffff;
$black: #000000;
$red: #ED4F2B;
$yellow: #fecb47;
$green: #22ce8c;
$background: #ffffff;
$secondary-background: #f4f5f6;
$chat-background: #e5e5e5;
$line: #eaebed;
$line2: #f4f5f6;
$text: #1c222e;
$text-secondary: #565657;
$greyLight: #e6e7e9;

@mixin mobile {
  @media (max-width: 575px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 576px) and (max-width: 991px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin tablet-desktop {
  @media (min-width: 576px) {
    @content;
  }
}


@mixin hidden-scrollbar {
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  scrollbar-height: none;  /* Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}
