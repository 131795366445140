//@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');
@import '~nprogress/nprogress.css';
@import './antd.css';
@import './base/all';
@import './components/all';
@import './layout/all';
@import './pages/all';

html,
body {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

#nprogress {
  .bar {
    background: $primary !important;
    height: 5px !important;
  }

  .spinner {

    &-icon {
      width: 28px;
      height: 28px;
      border-width: 4px !important;
      border-top-color: #7CC358;
      border-left-color: #7CC358;
    }
  }
}

body.page-loading,
body.menu-visible {
  overflow: hidden;
}

#page-loading-logo {
  z-index: 8888;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .text {
    margin-top: -100px;
    margin-left: -50px;
  }

  .loading-wrapper {
    margin-top: -100px;
    z-index: 1;
    .loading-content {
      width: 54px;
      height: 54px;
    }
  }
}

a {
  color: inherit;
  text-decoration: none;
}

img, svg, video, canvas, audio, iframe, embed, object {
  display: unset;
  vertical-align: unset;
}

.break-words {
  overflow-wrap: break-word;
  word-break: break-word;
}

* {
  box-sizing: border-box;
}

.ant-col {
  min-width: 0;
}

.ant-modal {
  &-content {
    border-radius: 16px;
  }

  &-header {
    border-radius: 16px 16px 0 0;
  }

  &-body {
  }

  &-footer {
    border-radius: 0 0 16px 16px;
  }
}

.ant-divider {
  &-inner-text {
    font-size: 14px;
    font-weight: normal;
    color: $text;
  }
}

.anticon {
  vertical-align: 2px;
}

.ant-tag {
  font-size: 13px;
  border-radius: 4px;
}

.ant-checkbox {
  &-inner {
    border-radius: 2px;
  }
}

.ant-input-affix-wrapper {
  .ant-input {
    border-radius: 0;
  }
}

.ant-input-group {
  &-addon {
    @apply bg-line bg-opacity-50 px-18;
  }

  .ant-input {
    border-left-color: transparent;
  }
}

.ant-dropdown {
  &-menu {
    &-item {
      padding: 12px;
    }
  }
}

.ant-radio {
  margin: 0 2px;
}
span.ant-radio + * {
  @apply px-12;
}

.ant-select {
  $select: &;

  &-multiple {
    #{$select}-selector {
      padding-top: 1px;
      padding-bottom: 1px;
    }

    #{$select}-selection-item {
      border-radius: 4px;
      font-weight: 500;
      font-size: 16px;

      &-remove {
        padding: 0 4px;
        > .anticon {
          vertical-align: middle;
        }
      }
    }
  }
}

.ant-empty {
  display: flex;
  flex-direction: column;
  align-items: center;

  &-image {
    height: auto;
    margin-bottom: 20px;
  }

  &-description {
    font-size: 18px;
    font-weight: 600;
  }
}

.country-input {
  .ant-input-group {
    &-addon {
      @apply /*bg-white*/
      px-12;
    }
  }
}

.country-select {
  @apply text-22 leading-none;

  .ant-select {
    &-selection-item {
      width: 42px;
      padding-right: 16px !important;
    }

    &-arrow {
      width: 24px;
      height: 24px;
      margin-top: -12px;
      right: 4px;
    }
  }
}

.search-bar {
  box-shadow: none;
  border-color: transparent;
  background-color: $line2;

  input {
    background-color: $line2;
  }

  &.ant-input-affix-wrapper-focused {
    border-color: $primary;
    background-color: white;

    input {
      background-color: white;
    }
  }
}

.react-viewer {
  &-mask {
    background-color: rgba(0, 0, 0, 0.9) !important;
  }
}

// https://stackoverflow.com/questions/41698357/how-to-partition-input-field-to-appear-as-separate-input-fields-on-screen
.otp-input {
  &-inner {
    left: 0;
    position: sticky;
  }
  &-outer {
    width: #{32 * 6 + 22 * 5}px;
    overflow: hidden;
  }
  &-input {
    padding: 0;
    border: 0;
    border-radius: 0;

    font-family: 'Source Code Pro', monospace;
    font-size: 32px;
    font-weight: 600;
    width: #{32 * 6 + 22 * 5 + 32}px;
    padding-left: 7px;
    letter-spacing: 35px;

    background-image: linear-gradient(to left, #7CC358 60%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: #{32 + 22}px 3px;
    background-repeat: repeat-x;
    background-position-x: 32px;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

.otp-verification {
  &-container {
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
  }

  &-input {
    flex: 1;
    width: 100%;
    height: 48px;
    margin: 0 10px;
    font-size: 32px;
    font-weight: 500;
    text-align: center;
    border-bottom: 2px solid #f5f5f5;
    outline: 2px solid transparent;
    outline-offset: 2px;

    &:focus, &.active {
      border-bottom-color: $primary;
    }

    &.error {
      border-bottom-color: $red !important;
    }
  }
}

.grecaptcha-badge {
  display: none !important;
}

.ant-form-item {

  .ant-form-item-explain {
    margin-bottom: 12px;
    font-size: 13px;
    @include tablet-desktop {
      font-size: 14px;
    }
  }

  &.hint-block {
    margin-bottom: 24px;

    .ant-input-affix-wrapper {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .ant-form-item-explain {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      overflow: hidden;
    }
  }
}

@include tablet-desktop {
  .ant-input {
    @apply text-18;
  }

  .ant-form-item {
    &-label > label {
      @apply text-16;
    }

    &-control {
      &-input {

      }
    }
  }
}
