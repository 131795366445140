.main-layout, .admin-layout {
  @apply bg-white;
  min-height: calc(100vh);
  min-height: calc(var(--vh, 1vh) * 100);

  &__header {
    @apply bg-white px-0;
    z-index: 100;
    @apply sticky top-0;
    transition: all 0.3s;

    &.sticky {
      @apply bg-white #{'!important'};
      box-shadow: 0 4px 4px rgba($black, 0.04);

      .main-header {
        @apply border-b-0;
      }
    }
  }

  &__content {
    > div {
      min-height: calc(100vh - 64px /* header */);
      min-height: calc(var(--vh, 1vh) * 100 - 64px);
    }
  }

  &__footer {
    @apply bg-white p-0 overflow-hidden;

    .footer-link {
      @apply text-default opacity-80;
    }
  }

  &__sidebar {
    background-color: $white;
    box-shadow: 4px 0px 4px rgba($black, 0.04);
    z-index: 11;

    .ant-menu {
      &-root {
        padding: 0 16px;
      }

      &-item {
        padding: 0 16px;

        a {
          color: $text !important;
        }

        &::after {
          border-right: none;
        }

        &-active {
          svg path {
            stroke: $primary !important;
          }
          a {
            color: $primary !important;
          }
        }

        &-selected {
          background-color: $primary;
          border-radius: 16px;
          svg path {
            stroke: $primary !important;
          }
          a {
            color: $primary !important;
          }
        }
      }
    }
  }

  &.transparent-header {
    .main-layout__header {
      &.menu-hidden {
        @apply bg-transparent;
      }

      @include desktop {
        @apply bg-transparent;
      }
    }
  }
}
