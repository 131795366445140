.app-modal {
  .ant-modal {
    &-header {
      text-align: center;
    }

    &-content {
      max-width: 520px;
    }

    &-footer {
      border-top: none;
      padding: 0 24px 24px 24px;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;

      button {
        flex: 1;
      }
    }
  }

  &.title-borderless {
    .ant-modal {
      &-header {
        border-bottom: none;
      }

      &-body {
        padding-top: 0;
      }
    }
  }
}

.large-modal {
  .ant-modal {
    &-content {
      max-width: none;
    }
  }
}