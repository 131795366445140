.auth {
  @include mobile {
    height: calc(100vh - 64px); /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100 - 64px);
    transition: all 0.3s;
  }

  .content {
    @apply container mx-auto max-w-560;
  }
}

.application-form {

  &__dropdown {
    min-width: 177px !important;
  }
}
