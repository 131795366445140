.terms {
  @apply container mx-auto;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;

  @include tablet-desktop {
    font-size: 16px;
  }

  p {
    margin-bottom: 16px;
  }

  ol {
    list-style-type: decimal;
    list-style-position: outside;
    padding-left: 24px;
    margin-bottom: 16px;

    li {
      margin-bottom: 8px;
    }
  }
}

.faqs {
  @apply container mx-auto;

  a {
    color: $blue;
  }

  .site-collapse-custom-collapse {
    background: white;

    .site-collapse-custom-panel {
      margin-bottom: 16px;
      overflow: hidden;
      border: 0;
      border-radius: 2px;

      .ant-collapse-header {
        background: $primary;
        color: white;
        font-weight: 600;
        font-size: 14px;

        @include tablet-desktop {
          font-size: 16px;
        }
      }

      .ant-collapse-content {
        background: #f7f7f7;
        font-size: 14px;

        @include tablet-desktop {
          font-size: 16px;
        }

        &-box {
          padding-top: 16px;
        }
      }
    }
  }
}

.giving-back {
  @apply relative overflow-hidden;

  .background {
    @apply absolute -top-48 -left-16 -right-60;
    @apply pointer-events-none;
    width: 120vw;
    height: 500px;

    @include tablet {
      height: 400px;
    }

    @include desktop {
      height: 350px;
    }

    img {
      @apply object-fill;
      @apply w-full h-full;
    }
  }

  .content {
    @apply relative container mx-auto px-24 py-32 flex flex-col;

    .charity {
      box-shadow: 0px 0px 28px rgba(0, 0, 0, 0.07);
    }
  }
}

.how-it-works {
  @apply relative overflow-hidden;

  .content {
    @apply relative container mx-auto px-24 py-32;
    @apply flex flex-col justify-center items-center;

    &.section {
      @apply md:flex-row md:space-x-64;
    }

    .image {
    }

    .order {
      @apply mt-40 w-44 h-44 md:w-52 md:h-52 rounded-full flex justify-center items-center;
      background-color: #E5F3DE;
    }

    .title {
      @apply mt-8 mb-0 text-center;
    }

    .description {
      @apply mt-8 mb-0 text-center;
    }

    .button {
      @apply mt-24 md:min-w-300;
    }
  }

  .background {
    @apply relative overflow-visible h-0;
    @apply pointer-events-none;

    img {
      @apply absolute top-0 w-screen;
      @apply object-fill;
    }
  }
}
