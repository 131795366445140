.payment-completed {

  .content {
    @apply container mx-auto max-w-560 px-16 py-16;
  }

  .banner {
    @include mobile {
      background-image: url("/images/bg-payment-completed.svg");
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}

.payment-checkout {
  height: calc(100vh - 64px);
  height: calc(var(--vh, 1vh) * 100 - 64px);
  transition: all 0.3s;

  .content {
    @apply container mx-auto px-24 lg:px-0;
  }
}

.payment-completed {
  height: calc(100vh);
  height: calc(var(--vh, 1vh) * 100);
}
