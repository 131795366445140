.main-header, .admin-header {
  @apply container mx-auto border-b-1 border-line px-24 lg:px-0;

  .link {
    @apply text-default;
    &:hover, &.selected {
      @apply text-primary;
    }
  }
}

.main-menu, .admin-menu, .sidebar-menu {
  @include mobile {
    width: 100vw;
    height: calc(100vh);
    height: calc(var(--vh, 1vh) * 100);
    border-radius: 0;
    box-shadow: none !important;
    border-top: solid 1px $line2;
  }

  @include tablet-desktop {
    min-width: 300px;
    border-radius: 8px !important;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%) !important;
  }

  &__overlay {
    @include mobile {
      position: fixed;
      top: 64px !important;
    }
  }

  .ant-menu-item {
    @apply h-auto;
    @apply py-16 px-24 text-16;
    @apply my-0 #{'!important'};
    @apply font-medium;

    &-icon {
      @apply mr-16;
    }

    &:hover, &-selected {
      @apply text-primary;
      svg {
        path {
          stroke: $primary;
        }
      }
    }

    &.logout {
      margin-top: 16px !important;;
    }
  }
}

.sidebar-menu {
  height: 100%;
  border-radius: 0 !important;
  box-shadow: none !important;
  @apply py-32;

  @include tablet-desktop {
    min-width: unset;
  }

  .ant-menu-item {
    @apply py-12 px-16;

    &-selected {
      background-color: white !important;
    }
  }
}
