.avatar {
  img {
    width: 100%;
    height: 100%;
  }

  &__text:not(.ant-avatar-image) {
    background-color: rgba($primary, 0.3);
    color: $primary;
    font-weight: bold;
  }
}
