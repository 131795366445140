button.ant-btn {

  @include tablet {
    @apply font-semibold text-16;
  }

  @include desktop {
    @apply font-semibold text-16;
  }

  //min-width: 48px;

  //&[disabled] {
  //  color: rgba($default, 0.5);
  //  border: none;
  //
  //  &:hover,
  //  &:focus {
  //    color: rgba($default, 0.5);
  //  }
  //}
  //
  //&.ant-btn-sm {
  //}
  //
  //&.ant-btn-lg {
  //}
  //
  //&-primary {
  //  color: $white;
  //  font-weight: bold;
  //}
}

.ant-switch {
  background-color: $greyLight;

  &-handle {
    &::before {
      background-color: rgba($default, 0.4);
      box-shadow: none;
    }
  }

  &-checked {
    background-color: $primary;

    .ant-switch-handle {
      &::before {
        background-color: $white;
      }
    }
  }
}
