.admin-details {
  .ant-form-item {

    margin-bottom: 8px;

    &-label {
      font-size: 13px;
      font-weight: 400;
    }

    &-control {
      &-input {
        min-height: 0;

        &-content {
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
  }
}

.refund-result-list {
  @apply flex flex-col space-y-2 list-disc list-inside overflow-auto;
  max-height: 50vh;
}

.table-custom {
  th,
  td {
    max-width: 200px;
    width: 200px;
    padding: 3px 15px;
    vertical-align: initial;
  }

  .capitalize {
    text-transform: capitalize;
  }
}
.payment-method-wrapper {
  table {

    td {
      border-top: 1px solid #bfb9b9;
    }

    th,
    td {
      max-width: 180px;
      width: 180px;
      padding: 3px 10px;
      vertical-align: initial;
      text-align: center;

      &.price {
        width: 100px;
      }

      &.next-subscription-date {
        width: 250px;
        max-width: 250px;
      }
    }
  }
}

.payment-history-wrapper {
  table {

    td {
      border-top: 1px solid #bfb9b9;
    }

    th,
    td {
      padding: 3px 10px !important;
      vertical-align: inherit;
      height: 55px;
      text-align: center;

      &.amount {
        width: 100px;
      }

      &.status {
        width: 150px;
      }
    }
  }
}

.refund-modal {
  table {

    th,
    td {
      border: solid 1px #bfb9b9;

      &.price {
        width: 100px;
      }

      &.date {
        width: 200px;
      }
    }

  }
  
  .reason-box {
    border: 1px solid #bfb9b9;
    height: 6em;
    width: 100%;
  }
}

.payment-history-modal {
  .ant-modal-body {
    overflow: auto;
    max-height: 80vh;
  }
}