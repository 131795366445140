.ant-tabs {
  &-nav {
    @apply mb-0;
  }

  &-tab {
    @apply mx-24;

    &-btn {
      font-size: 14px;

      @include tablet-desktop {
        font-size: 18px;
      }
    }
  }

  &-centered {
    .ant-tabs {
      &-tab {
        flex: 1;
        margin: 0px;
        justify-content: center;
        overflow: visible;
        @include mobile {
          margin: 0 16px;
        }

        &:hover {
          color: $default;
        }

        &-active {
          .ant-tabs-tab-btn {
            color: $primary;
            font-weight: bold !important;
          }
        }
      }

      &-nav {
        margin: 0 !important;
        border-bottom: 1px solid $line;

        &::before {
          display: none;
        }

        &-list {
          flex: 1;
          justify-content: space-between;
        }

        &-operations {
          display: none !important;
        }
      }

      &-ink-bar {
        background: transparent;

        &::after {
          content: ' ';
          position: absolute;
          left: 50%;
          right: 0;
          bottom: 0;
          background: $primary;
          width: 96px !important;
          height: 2px !important;
          transform: translateX(-50%);
          border-radius: 123px;
          @include mobile {
            width: 100% !important;
          }
        }
      }
    }

  }
}
